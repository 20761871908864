/**
 * This is a high level startup event manager. It has a hidden component which is inlined
 * within the page for events that complete faster than the bundle can be loaded.
 */
import { Deferred } from "./deferred";
import { ILog } from "./logging";

const log = ILog.logger("Startup/ready.ts");

export enum StartupEvent {
  DOM = "DOM",
  POLY = "POLY",
  MOAT = "MOAT",
  BRIDGE = "BRIDGE",
  GPT = "GPT",
}

export type StartupEventMap = {
  [k in StartupEvent]?: boolean;
};

export class RuntimeStartup {
  private events: { [s: string]: Deferred } = {};

  constructor() {
    Object.keys(StartupEvent).forEach(k => {
      this.events[k] = new Deferred();
      if (this.eventCompletedDuringInit(k as StartupEvent)) {
        this.signal(StartupEvent[k]);
      }
    });
  }

  private eventCompletedDuringInit(event: StartupEvent) {
    if (typeof window === "undefined") {
      return;
    }
    // @ts-ignore
    return !!(window.bootEvents || {})[event];
  }

  waitFor(...events: StartupEvent[]) {
    return Promise.all(events.map(e => this.events[e]));
  }

  getDeferredEvent = (event: StartupEvent) => {
    return this.events[event];
  };

  registerGlobalCallback(event: StartupEvent, fnName: string, timeout = 0) {
    if (typeof window === "undefined") {
      return;
    }

    if (this.eventCompletedDuringInit(event)) {
      log.debug(`Startup event ${event} already done`);
      this.signal(event);
    } else {
      log.debug(`Registering startup event ${event}`);
      let timer: ReturnType<typeof setTimeout>;
      let timedOut = false;

      if (timeout) {
        timer = setTimeout(() => {
          timedOut = true;
          this.fail(event);
        }, timeout);
      }

      (window as any)[fnName] = () => {
        if (timer) {
          window.clearTimeout(timer);
        }

        if (!timedOut) {
          log.debug(`Triggering startup event handler ${fnName}`);
          return this.signal(event);
        }
      };
    }
  }

  signal(event: StartupEvent) {
    log.debug(`Signalling startup complete for ${event}`);
    this.events[event].resolve(true);
    return true;
  }

  fail(event: StartupEvent) {
    log.debug(`Signalling startup failed for ${event}`);
    this.events[event].reject(false);
    return false;
  }

  hasBeenInit = false;
  init = () => {
    if (this.hasBeenInit) {
      return this;
    }
    this.registerGlobalCallback(StartupEvent.DOM, "domCompleteReady");
    // We need to wait up to 250ms on the MOAT data to return, if it does not carry on.
    this.registerGlobalCallback(StartupEvent.MOAT, "moatYieldReady", 250);
    this.hasBeenInit = true;
    return this;
  };
}

export const startup = new RuntimeStartup();
